/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import api from '@utils/axios';
import { translate } from '@utils/i18n';
import toast from 'react-hot-toast';
import { apiInternalVisitPath, checkAvailableTechniciansPath } from 'routes';
import { Duration } from 'luxon';
import Modal from '@components/Modal';
import { Button, ButtonVariant, LoadableButton } from '@components/Button';
import TimeInput from '@components/TimeInput/TimeInput';
import Select from '@components/Select/Select';
import InputTextArea, { StyleVariant } from '@components/InputTextArea/InputTextArea';
import DateInput from '@components/DateInput/DateInput';
import FormTypeSelector from './components/FormTypeSelector';
import TextInput from './components/TextInput';
import { getDMHfromMins } from '../../../utils/getDatetimeFormat';

const namespace = 'features.work_orders';

export default function EditVisitModal({
  visit = {},
  isOpen,
  woId,
  onClose,
  techOptions,
  vendorOptions,
  statusesOptions,
  onEditComplete,
  defaultTechnicianId,
  defaultSubcontractorId
}) {
  const [loading, setIsLoading] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [checkAvailableNotification, setCheckAvailableNotification] = useState({ message: '', data: null });
  const { register, handleSubmit, control, setValue, reset, watch } = useForm({
    defaultValues: {
      id: visit?.id,
      technicianId: visit?.technician_id || defaultTechnicianId || '',
      subcontractorId: visit?.subcontractor_id || defaultSubcontractorId || '',
      status: visit?.visit_status,
      scheduledDate: new Date(visit?.scheduled_date),
      days: getDMHfromMins(visit?.duration).days,
      hours: getDMHfromMins(visit?.duration).hours,
      minutes: getDMHfromMins(visit?.duration).minutes,
      workPerformed: visit?.work_performed,
      notesToVendor: visit?.notes,
      followUp: visit?.follow_up,
      poNumber: visit?.subcontractor_po_number,
      billingLimit: visit?.subcontractor_billing_limit
    }
  });

  useEffect(() => {
    let controller = new AbortController();

    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') return;

      if (!['minutes', 'hours', 'days', 'technicianId', 'scheduledDate'].includes(name)) return;
      if (!parseInt(value.technicianId, 10)) {
        setCheckAvailableNotification({ message: '', data: null });
        return;
      }

      controller.abort();
      controller = new AbortController();

      api
        .get(checkAvailableTechniciansPath({ format: 'json' }), {
          signal: controller.signal,
          params: {
            default_object_name: 'Visit',
            default_object_id: value.id,
            technician_id: value.technicianId,
            scheduled_date: value.scheduledDate,
            duration: Duration.fromObject({
              days: value.days,
              hours: value.hours,
              minutes: value.minutes
            }).as('minutes')
          }
        })
        .then(() => setCheckAvailableNotification({ message: '', data: null }))
        .catch((error) =>
          setCheckAvailableNotification({
            message: error?.response?.data?.message,
            data: error?.response?.data?.data
          })
        );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (newVisitInfo) => {
    if (woId) return onSubmitOnPersisted(newVisitInfo);

    return onSubmitOnNewRecord(newVisitInfo);
  };

  const onSubmitOnNewRecord = (newVisitInfo) => {
    onEditComplete({
      id: newVisitInfo.id,
      scheduled_date: newVisitInfo.scheduledDate,
      duration: Number(newVisitInfo.days) * 1440 + Number(newVisitInfo.hours * 60) + Number(newVisitInfo.minutes),
      technician_id: !isVendor ? Number(newVisitInfo.technicianId) || null : null,
      notes: isVendor ? newVisitInfo.notesToVendor : undefined,
      visit_status: newVisitInfo.status ? newVisitInfo.status : undefined,
      subcontractor_id: isVendor ? Number(newVisitInfo.subcontractorId) || null : null,
      subcontractor_po_number: newVisitInfo.poNumber ? newVisitInfo.poNumber : undefined,
      subcontractor_billing_limit: newVisitInfo.billingLimit ? newVisitInfo.billingLimit : undefined,
      work_performed: newVisitInfo.workPerformed || undefined,
      follow_up: newVisitInfo.followUp || undefined,
      assignee_type: isVendor ? 'subcontractor' : 'technician'
    });
  };

  const onSubmitOnPersisted = async (newVisitInfo) => {
    try {
      setIsLoading(true);
      const visitInfo = {
        scheduled_date: new Date(newVisitInfo.scheduledDate),
        duration: Number(newVisitInfo.days) * 1440 + Number(newVisitInfo.hours * 60) + Number(newVisitInfo.minutes),
        technician_id: !isVendor ? newVisitInfo.technicianId ?? null : null,
        work_order_id: visit.work_order_id,
        notes: isVendor ? newVisitInfo.notesToVendor : undefined,
        work_performed: newVisitInfo.workPerformed,
        follow_up: newVisitInfo.followUp,
        visit_status: newVisitInfo.status ? newVisitInfo.status : undefined,
        subcontractor_id: isVendor ? newVisitInfo.subcontractorId ?? null : null,
        subcontractor_po_number: newVisitInfo.poNumber && isVendor ? newVisitInfo.poNumber : undefined,
        subcontractor_billing_limit: newVisitInfo.billingLimit && isVendor ? newVisitInfo.billingLimit : undefined,
        assignee_type: isVendor ? 'subcontractor' : 'technician'
      };

      const { data: res } = await api.patch(apiInternalVisitPath({ id: visit.id, format: 'json' }), {
        visit: visitInfo
      });
      setIsLoading(false);
      onEditComplete(res.data);
      reset();
      setIsVendor(false);
    } catch (e) {
      toast.error(translate('edit_error', { namespace }), {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  useEffect(() => {
    setIsVendor(visit?.assignee_type === 'subcontractor');
    setValue('scheduledDate', new Date(visit?.scheduled_date));
    setValue('days', getDMHfromMins(visit?.duration).days);
    setValue('hours', getDMHfromMins(visit?.duration).hours);
    setValue('minutes', getDMHfromMins(visit?.duration).minutes);
    setValue('technicianId', visit?.technician_id || defaultTechnicianId);
    setValue('subcontractorId', visit?.subcontractor_id || defaultSubcontractorId);
    setValue('status', visit?.visit_status);
    setValue('notesToVendor', visit?.notes || '');
    setValue('workPerformed', visit?.work_performed || '');
    setValue('followUp', visit?.follow_up || '');
    setValue('poNumber', visit?.subcontractor_po_number || '');
    setValue('billingLimit', visit?.subcontractor_billing_limit || '');
    setValue('id', visit?.id ?? '');
  }, [visit]);

  const onClickCancel = () => {
    onClose();
    reset();
    setIsVendor(false);
  };

  const onChangeAsigneeType = ({ isVendor: newIsVendor }) => {
    setIsVendor(newIsVendor);
  };

  const technicianOptions = techOptions.map(([name, id]) => ({ label: name, value: id }));
  const subcontractorOptions = useMemo(
    () => (vendorOptions[visit?.id] || vendorOptions.default).map(([name, id]) => ({ label: name, value: id })),
    [visit]
  );

  const statuses = statusesOptions.map(([name, id]) => ({ label: name, value: id }));

  return (
    <Modal
      title={translate('edit_visit', { namespace })}
      isOpen={isOpen}
      onClose={onClose}
      width={600}
      height={isVendor ? 510 : 368}>
      <section className="qmb-modal__body visitModal">
        <form className="modalForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="formSection">
            <FormTypeSelector isVendor={isVendor} onChange={onChangeAsigneeType} />
          </div>
          <div className="formSection equalChildrens">
            {!isVendor && (
              <Select
                {...register('technicianId')}
                id="technicianId"
                noOptionLabel={translate('unassigned', { namespace })}
                label={translate('technician', { namespace })}
                options={technicianOptions}
              />
            )}
            {isVendor && (
              <Select
                {...register('subcontractorId')}
                id="subcontractorId"
                noOptionLabel={translate('unassigned', { namespace })}
                label={translate('subcontractor', { namespace })}
                options={subcontractorOptions}
              />
            )}
            <Select {...register('status')} label={translate('status', { namespace })} id="status" options={statuses} />
          </div>
          {isVendor && (
            <div className="formSection">
              <div className="equalChildrens">
                <Controller
                  name="poNumber"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      id="poNumber"
                      value={field.value}
                      name={field.name}
                      onChange={field.onChange}
                      label={`${translate('po', { namespace })} #`}
                    />
                  )}
                />
                <Controller
                  name="billingLimit"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      id="billingLimit"
                      value={field.value}
                      name={field.name}
                      onChange={field.onChange}
                      type="number"
                      min={0}
                      label={translate('billing_limit', { namespace })}
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div className="formSection">
            <div className="equalChildrens">
              <Controller
                name="scheduledDate"
                control={control}
                render={({ field }) => (
                  <DateInput
                    id="scheduledDate"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    label={translate('scheduled_date', { namespace })}
                  />
                )}
              />
              <Controller
                name="scheduledDate"
                control={control}
                render={({ field }) => (
                  <TimeInput
                    id="scheduledDate"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    label={translate('start_time', { namespace })}
                  />
                )}
              />
            </div>
            <div className="equalChildrens">
              <span>{translate('duration', { namespace })}:</span>
              <Controller
                name="days"
                control={control}
                render={({ field }) => (
                  <TextInput
                    id="days"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    label={translate('days', { namespace: 'features.commons' })}
                    type="number"
                    min={0}
                  />
                )}
              />
              <Controller
                name="hours"
                control={control}
                render={({ field }) => (
                  <TextInput
                    id="hours"
                    type="number"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    min={0}
                    label={translate('hours', { namespace: 'features.commons' })}
                  />
                )}
              />
              <Controller
                name="minutes"
                control={control}
                render={({ field }) => (
                  <TextInput
                    id="minutes"
                    type="number"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    min={0}
                    label={translate('minutes', { namespace: 'features.commons' })}
                  />
                )}
              />
            </div>
          </div>
          {!isVendor && (
            <div style={{ margin: 'auto' }}>
              {checkAvailableNotification.message}
              <br />
              {checkAvailableNotification.data && (
                <a href={checkAvailableNotification.data.link} target="_blank" rel="noreferrer">
                  {checkAvailableNotification.data.text_link}
                </a>
              )}
            </div>
          )}
          <div className="formSection equalChildrens vertical">
            <Controller
              name="workPerformed"
              control={control}
              render={({ field }) => (
                <InputTextArea
                  label={translate('work_performed', { namespace })}
                  value={field.value}
                  name={field.name}
                  buttonControlled={false}
                  variant={StyleVariant.Light}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              name="followUp"
              control={control}
              render={({ field }) => (
                <InputTextArea
                  label={translate('follow_up', { namespace })}
                  value={field.value}
                  name={field.name}
                  buttonControlled={false}
                  variant={StyleVariant.Light}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="modalActionContainer">
            <Button onClick={onClickCancel}>{translate('cancel', { namespace: 'features.commons' })}</Button>
            <LoadableButton type="submit" loading={loading} variant={ButtonVariant.Primary}>
              {translate('save', { namespace: 'features.commons' })}
            </LoadableButton>
          </div>
        </form>
      </section>
    </Modal>
  );
}

EditVisitModal.propTypes = {
  woId: PropTypes.number,
  onEditComplete: PropTypes.func.isRequired,
  visit: PropTypes.object,
  onEditVisit: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  techOptions: PropTypes.array,
  vendorOptions: PropTypes.object,
  statusesOptions: PropTypes.array,
  defaultTechnicianId: PropTypes.number,
  defaultSubcontractorId: PropTypes.number
};

EditVisitModal.defaultProps = {
  woId: null,
  visit: {},
  onEditVisit: () => {},
  isOpen: false,
  onClose: () => {},
  techOptions: [],
  vendorOptions: {},
  statusesOptions: [],
  defaultTechnicianId: null,
  defaultSubcontractorId: null
};
