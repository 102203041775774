import React, { useContext, useRef, useState } from 'react';
import Table, { TableSize } from '@components/Table';
import { Button, ButtonSize, ButtonVariant } from '@components/Button';
import { translate } from '@utils/i18n';
import { Popup } from '@progress/kendo-react-popup';
import getColumnsDefinition from './columns';
import { SignaturesListContext, SignaturesListContextProvider } from './SignaturesListContext';
import './SignatureList.scss';
import SignatureTypesPopup from './SignatureTypesPopup';
import SignatureForm from './SignatureForm';
import { SignatureFormContext, SignatureFormContextProvider } from './SignatureFormContext';
import { ToastContextProvider } from '../../contexts/toast-context';
import { TenantContextProvider } from '../../contexts/tenant-context';
import { TimeZoneContextProvider } from '../../contexts/timezone-context';
const namespace = 'features.signatures_list';
function SignaturesList() {
    const { signatures, deleteSignature } = useContext(SignaturesListContext);
    const { setSignature } = useContext(SignatureFormContext);
    const [popupOpen, setPopupOpen] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const buttonRef = useRef(null);
    return (React.createElement("section", { className: "workspace__section signatureListContainer" },
        React.createElement("article", { className: "section__content" },
            signatures.length > 0 && (React.createElement(Table, { columns: getColumnsDefinition({ handleOnDelete: deleteSignature, handleOnClick: setSignature }), data: signatures, size: TableSize.Full })),
            React.createElement(Button
            // ref={buttonRef}
            , { 
                // ref={buttonRef}
                onClick: () => setPopupOpen((prevState) => !prevState), variant: ButtonVariant.BlueAccent, size: ButtonSize.Full },
                React.createElement("i", { className: "fa-light fa-circle-plus" }),
                translate('add_signature', { namespace })),
            React.createElement(Popup, { anchor: buttonRef.current, show: popupOpen },
                React.createElement(SignatureTypesPopup, { onSelect: () => {
                        setPopupOpen(false);
                    } })))));
}
function SignaturesListWrapper(params) {
    return (React.createElement(TimeZoneContextProvider, { initialTimezone: params.timeZone },
        React.createElement(TenantContextProvider, null,
            React.createElement(ToastContextProvider, null,
                React.createElement(SignaturesListContextProvider, { signatureOwner: params.signatureOwner, signatureOwnerId: params.signatureOwnerId, defaultTechnicianId: params.defaultTechnicianId },
                    React.createElement(SignatureFormContextProvider, { resourceType: params.signatureOwner, resourceId: params.signatureOwnerId },
                        React.createElement(SignaturesList, null),
                        React.createElement(SignatureForm, null)))))));
}
export default SignaturesListWrapper;
