import React, { useMemo } from 'react';
function LegendStatus(params) {
    const { foregroundColor, backgroundColor } = useMemo(() => {
        return { foregroundColor: params.status.color, backgroundColor: `${params.status.color}4D` };
    }, [params.status]);
    return (React.createElement("div", { className: "legend__status" },
        React.createElement("div", { className: "status__indicator", style: { background: backgroundColor } },
            React.createElement("div", { className: "status__indicator_rectangle", style: { background: foregroundColor } })),
        React.createElement("span", { className: "status__name" }, params.status.label)));
}
export default LegendStatus;
